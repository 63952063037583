.lds-ouroboro {
  position: relative;
  display:inline-block;
  height: 64px;
  width: 64px;
  margin: 0.5em;
  border-radius: 50%;  
  overflow:hidden;
  box-shadow: 0 0 10px rgba(0,0,0,.1) inset, 0 0 25px rgba(0,0,255,0.075);
}

.lds-ouroboro:after {
  content: "";
  position: relative;
  top: 15%; left: 15%;
  display: block;
  height: 70%; width:70%;
  background: none repeat scroll 0 0 #F2F2F2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
}
.lds-ouroboro > span {
  position: absolute;
  height: 100%; width: 50%;
  overflow: hidden;
}
.lds-ouroboro > .left  { left:0   }
.lds-ouroboro > .right { left:50% }

.lds-ouroboro > .left > .anim , .lds-ouroboro > .right > .anim {
  position: absolute;
  left: 100%; top: 0;
  height: 100%; width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 #508EC3;
  animation: lds-ouroboro-rotate 3s infinite;
  opacity: 0.8;
  transform-origin: 0 50% 0;
}
.lds-ouroboro > .left > .anim {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.lds-ouroboro > .right > .anim {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  transform-origin: 100% 50% 0;
}

@keyframes lds-ouroboro-rotate{
0%{transform:rotate(0deg)}
25%{transform:rotate(0deg)}
50%{transform:rotate(180deg)}
75%{transform:rotate(180deg)}
100%{transform:rotate(360deg)}
}
